import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Banner from '@/components/Banner/Banner'
import SEO from '../components/seo'
import Hero from '../components/Hero/Hero'
import { CaseStudyCard } from '../components'
import AndMoreSection from '../components/AndMoreSection/AndMoreSection'
// eslint-disable-next-line no-unused-vars
import { AllCaseStudies } from '@/graphql/AllCaseStudies'

const IndexPage = props => {
  const {
    data,
    pageContext: { lang },
  } = props
  if (!data) return null

  const homepage = data.prismicHomepage?.data
  const caseStudies = data.prismicProjectList.data.project_list

  return (
    <Layout locale={lang}>
      <SEO
        title={homepage?.page_title?.text ?? 'All Turtles'}
        description={
          homepage?.page_desc?.text ?? 'We make products that matter'
        }
        image={homepage?.social_image?.url}
      />
      <Banner
        text={homepage?.banner_text?.text}
        slug={homepage?.banner_cta_slug}
      />
      <Hero title={homepage?.hero_title?.text} isHomePage={true} />
      {caseStudies
        ?.filter(item => !item.show_into_secondary_section)
        ?.map(item => (
          <CaseStudyCard key={item.uid} url={item.uid} {...item} />
        ))}

      <AndMoreSection caseStudies={caseStudies} homepage={homepage} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ContentIndex($lang: String!) {
    prismicHomepage(lang: { eq: $lang }) {
      data {
        and_more
        more_about_us_label
        more_about_us_slug
        page_title {
          text
        }
        page_desc {
          text
        }
        social_image {
          url
        }
        hero_title {
          text
        }
        banner_text {
          text
        }
        banner_cta_slug
      }
    }
    prismicProjectList(lang: { eq: $lang }) {
      ...AllCaseStudies
    }
  }
`

export default IndexPage
